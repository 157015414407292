import v4 from 'node-uuid'
import { batchActions } from 'redux-batched-actions'
import {
    getAdditionalData,
    getCplateMapping,
    getDeviceList,
    getFrameMapping,
    getDesignChangeSpecialties,
} from '../../availableDevices/duck'
import { getEditableProject, setProjectCplateColor, setProjectFrameColor, setProjectSerie } from '../../projects/duck'
import { addDevice, deleteDevice } from '../device/duck'
import { getEntity, getEntityResolved, getOperationPointsFromEntity, getParentDesignRange, setConfigValue } from '../duck'
import {
    dereferenceDeviceWithOperationPoint,
    dereferenceFrameWithOperationPoint,
    getOrientation,
    referenceDeviceWithOperationPoint,
    referenceFrameWithOperationPoint,
} from '../operationPoint/duck'
import { calculateFrame } from '../../../logic/calculateFrame'
import { calculateDeviceForCPlate } from '../../../logic/calculateDeviceForCplate'
import { createSelector } from 'reselect'
import { calculatePossibleDevice } from '../../../logic/calculatePossibleDevice'

export const calculateChangeDesignRange = createSelector(
    [args => args.state, args => args.serieId, args => args.frameColorId, args => args.cPlateColorId, args => args.entityId, args => getDesignChangeSpecialties(args.state)],
    (state, serieId, frameColorId, cPlateColorId, entityId, designChangeSpecialties) => {
        const project = getEditableProject(state)
        const deviceList = getDeviceList(state)
        const cPlateMapping = getCplateMapping(state)
        const frameMapping = getFrameMapping(state)
        const additionalData = getAdditionalData(state)
        const operationPoints = getOperationPointsFromEntity(state, entityId ? entityId : project.data.rootEntityId)
        const currentDesignRange = getParentDesignRange(state, entityId ? entityId : project.data.rootEntityId)
        const entityResolved = getEntityResolved(state, entityId ? entityId : project.data.rootEntityId)
       
        return new Promise((resolve, reject) => {
            calculateActionsForDesignRangeChange(
                project,
                deviceList,
                cPlateMapping,
                frameMapping,
                additionalData,
                operationPoints,
                serieId,
                frameColorId,
                cPlateColorId,
                entityResolved,
                currentDesignRange,
                designChangeSpecialties
            ).then(data => resolve(data))
        })
    }
)

export const calculateActionsForDesignRangeChange = (
    project,
    deviceList,
    cPlateMapping,
    frameMapping,
    additionalData,
    operationPoints,
    serieId,
    frameColorId,
    cPlateColorId,
    entity,
    currentDesignRange,
    designChangeSpecialties
) => {
    return new Promise((resolve, reject) => {
        let error = false

        let changeFrame = false
        let changeDevice = false

        let affectedEntities = getAffectedEntities(entity)
        
        let actions = []

        if (currentDesignRange.serie !== serieId) {
            if (entity.type === 'building') {
                actions.push(setProjectSerie({ serie: serieId }))
            }
            affectedEntities.forEach(id => {
                actions.push(setConfigValue(id, {type: 'serie', value: serieId}))
            })
            changeFrame = true
            changeDevice = true
        }

        if (currentDesignRange.frameColor !== frameColorId) {
            if (entity.type === 'building') {
                actions.push(setProjectFrameColor({ frameColor: frameColorId }))
            }
            affectedEntities.forEach(id => {
                actions.push(setConfigValue(id, {type: 'frameColor', value: frameColorId}))
            })
            changeFrame = true
        }

        if (currentDesignRange.cplateColor !== cPlateColorId) {
            if (entity.type === 'building') {
                actions.push(setProjectCplateColor({ cplateColor: cPlateColorId }))
            }
            affectedEntities.forEach(id => {
                actions.push(setConfigValue(id, {type: 'cplateColor', value: cPlateColorId}))
            })
            changeDevice = true
        }

        if (changeFrame || changeDevice) {
            operationPoints.forEach(operationPoint => {
                let frameTypeId = 0
                if (operationPoint.devices) {
                    operationPoint.devices.forEach((device, index) => {
                        if (device) {
                            frameTypeId++
                            if (changeDevice) {
                                const oldCPlateMapping = cPlateMapping.find(
                                    el => el.serie === project.data.serie && el.colorId === project.data.cplateColor
                                )
                                const newCPlateMapping = cPlateMapping.find(
                                    el => el.serie === serieId && el.colorId === cPlateColorId
                                )
    
                                const availableDevice = deviceList.find(
                                    el => el.getSupplierpid() === device.availableDeviceId
                                )
    
                                if (oldCPlateMapping && newCPlateMapping && availableDevice) {
                                    let newArticleNumber = availableDevice
                                        .getManufacturertypedescr()
                                        .replace(
                                            `-${oldCPlateMapping.articleColor.toString()}`,
                                            `-${newCPlateMapping.articleColor.toString()}`
                                        )
    
                                    let newAvailableDevice = deviceList.find(
                                        el => el.getManufacturertypedescr() === newArticleNumber
                                    )

                                    
                                    
                                    if (!newAvailableDevice) {
                                        const foundReplace = designChangeSpecialties.find(el => newArticleNumber.includes(el.string))
                                        if (foundReplace) {
                                            newArticleNumber = newArticleNumber.replace(
                                                `${foundReplace.string}`,
                                                `${foundReplace.replace}`
                                            )
                                        }
                                        newAvailableDevice = deviceList.find(
                                            el => el.getManufacturertypedescr() === newArticleNumber
                                        )
                                    }

                                    if (newAvailableDevice) {
                                        if (newAvailableDevice.getSupplierpid() !== device.availableDeviceId) {
                                            actions.push(deleteDevice(device.id))
                                            actions.push(
                                                dereferenceDeviceWithOperationPoint(operationPoint.id, device.id)
                                            )
                                            const deviceId = v4()
                                            actions.push(
                                                addDevice(
                                                    newAvailableDevice.getSupplierpid(),
                                                    operationPoint.id,
                                                    deviceId,
                                                    'device'
                                                )
                                            )
                                            actions.push(referenceDeviceWithOperationPoint(operationPoint.id, deviceId))
                                        }
                                    } else {
                                        const features = JSON.parse(availableDevice.getFeatures())
                                        if (features) {
                                            if (features[0]) {
                                                
                                                let filters = []
                                                const description = {
                                                    feature: features[0].Id,
                                                    manufacturerTypeDescr:
                                                        '-' + newCPlateMapping.articleColor.toString(),
                                                    filters: [],
                                                }

                                                const possibleDevices = calculatePossibleDevice(
                                                    deviceList,
                                                    [{ ...description, filters }],
                                                    additionalData,
                                                    serieId
                                                )

                                                let bestPossibleDevice = {}
                                                if (possibleDevices.length > 0) {
                                                    let bestMatches = 0
                                                    possibleDevices.forEach(pDev => {
                                                        let matches = 0
                                                        const pFeatures = JSON.parse(pDev.features)
                                                        pFeatures[0].Feature.forEach(feature => {
                                                            if (
                                                                feature.Fname !== 'EF005474' &&
                                                                feature.Fname !== 'EF004293' &&
                                                                feature.Fname !== 'EF002442' &&
                                                                feature.Fname !== 'EF000008' &&
                                                                feature.Fname !== 'EF000040' &&
                                                                feature.Fname !== 'EF000049' &&
                                                                feature.Fname !== 'EF000846' &&
                                                                feature.Fname !== 'EF000332' &&
                                                                feature.Fname !== 'EF009277'
                                                            ) {
                                                                const matchedFeature = features[0].Feature.find(
                                                                    feat =>
                                                                        feat.Fname === feature.Fname &&
                                                                        feat.Fvalue === feature.Fvalue
                                                                )
                                                                if (matchedFeature) {
                                                                    matches++
                                                                }
                                                            }
                                                        })
                                                        if (matches > bestMatches) {
                                                            bestPossibleDevice = pDev
                                                            bestMatches = matches
                                                        }
                                                    })
                                                }
                                                if (bestPossibleDevice?.supplierPid
                                                    && bestPossibleDevice?.supplierPid !== device.availableDeviceId
                                                ) {
                                                    actions.push(deleteDevice(device.id))
                                                    actions.push(
                                                        dereferenceDeviceWithOperationPoint(
                                                            operationPoint.id,
                                                            device.id
                                                        )
                                                    )
                                                    const deviceId = v4()
                                                    actions.push(
                                                        addDevice(
                                                            bestPossibleDevice.supplierPid,
                                                            operationPoint.id,
                                                            deviceId,
                                                            'device'
                                                        )
                                                    )
                                                    actions.push(
                                                        referenceDeviceWithOperationPoint(
                                                            operationPoint.id,
                                                            deviceId
                                                        )
                                                    )
                                                    device.id = deviceId
                                                    device.availableDeviceId = bestPossibleDevice.supplierPid
                                                }
                                            }
                                        }
                                    }
                                }
                                if (device?.devices?.length > 0) {
                                    device.devices.forEach(dev => {
                                        if (newCPlateMapping) {
                                            const originParentDevice = deviceList.find(
                                                el => el.getSupplierpid() === device.availableDeviceId
                                            )
                                            
                                            const originDevice = deviceList.find(
                                                el => el.getSupplierpid() === dev.availableDeviceId
                                            )
        
                                            if (originParentDevice && originDevice) {
                                                let newArticleNumber = originDevice
                                                    .getManufacturertypedescr()
                                                    .replace(
                                                        `-${oldCPlateMapping.articleColor.toString()}`,
                                                        `-${newCPlateMapping.articleColor.toString()}`
                                                        )
        
                                                let newAvailableDevice = deviceList.find(el => {
                                                    return el.getManufacturertypedescr() === newArticleNumber
                                                })
        
                                                if (newAvailableDevice) {
                                                    newAvailableDevice = {
                                                        supplierPid: newAvailableDevice.getSupplierpid()
                                                    }
                                                }

                                                if (!newAvailableDevice) {
                                                    const foundReplace = designChangeSpecialties.find(el => newArticleNumber.includes(el.string))
                                                    if (foundReplace) {
                                                        newArticleNumber = newArticleNumber.replace(
                                                            `${foundReplace.string}`,
                                                            `${foundReplace.replace}`
                                                        )
                                                    }
                                                    newAvailableDevice = deviceList.find(
                                                        el => el.getManufacturertypedescr() === newArticleNumber
                                                    )

                                                    if (newAvailableDevice) {
                                                        newAvailableDevice = {
                                                            supplierPid: newAvailableDevice.getSupplierpid()
                                                        }
                                                    }
                                                }

                                                if (!newAvailableDevice && dev.type === 'cplate') {
                                                    const cPlates = calculateDeviceForCPlate(
                                                        originParentDevice.getSupplierpid(),
                                                        deviceList,
                                                        JSON.parse(originParentDevice.getFeatures()),
                                                        newCPlateMapping.articleColor,
                                                        additionalData,
                                                        device.devices
                                                            .filter(device => device.type === 'device')
                                                            .map(el => {
                                                                return deviceList.find(el2 => el2.getSupplierpid() === el.availableDeviceId)?.getManufacturertypedescr()
                                                            }).filter(el => el),
                                                        dev.position,
                                                        serieId
                                                    )
        
                                                    if (cPlates && cPlates.length > 0) {
                                                        newAvailableDevice = cPlates.find(el => el.IsDefault) || cPlates[0]
                                                    }
                                                }
        
                                                if (newAvailableDevice) {
                                                    if (newAvailableDevice.supplierPid !== dev.availableDeviceId) {
                                                        actions.push(
                                                            dereferenceDeviceWithOperationPoint(device.id, dev.id)
                                                        )
                                                        actions.push(deleteDevice(dev.id))
                                                        const deviceId = v4()
                                                        actions.push(
                                                            addDevice(
                                                                newAvailableDevice.supplierPid,
                                                                device.id,
                                                                deviceId,
                                                                dev.type,
                                                                undefined,
                                                                dev.position
                                                            )
                                                        )
                                                        actions.push(referenceDeviceWithOperationPoint(device.id, deviceId))
                                                    }
                                                } else {
                                                    actions.push(
                                                        dereferenceDeviceWithOperationPoint(device.id, dev.id)
                                                    )
                                                    actions.push(deleteDevice(dev.id))
                                                }
                                            }
                                        }
                                    })
                                }
                            }
                        }
                    })
                }

                if (changeFrame) {
                    const frame = calculateFrame(
                        deviceList,
                        serieId,
                        frameTypeId,
                        frameColorId,
                        getOrientation(operationPoint),
                        frameMapping
                    )

                    if (operationPoint.frame) {
                        actions.push(deleteDevice(operationPoint.frame.id))
                        actions.push(dereferenceFrameWithOperationPoint(operationPoint.id, operationPoint.frame.id))
                    }
                    if (frame) {
                        const frameId = v4()
                        actions.push(addDevice(frame.supplierPid, operationPoint.id, frameId, 'frame'))
                        actions.push(referenceFrameWithOperationPoint(operationPoint.id, frameId))
                    } else {
                        error = true
                    }
                }
            })
        }

        resolve({
            status: error ? 'error' : actions.length === 0 ? 'noChange' : 'success',
            action: batchActions(actions),
        })
    })
}

export const getDevicesFromEntity = (state, entityId) => {
    const entity = getEntity(state, entityId)
    let devices = []

    if (entity.floors) {
        entity.floors.forEach(floorId => {
            devices = devices.concat(getDevicesFromEntity(state, floorId))
        })
    }

    if (entity.rooms) {
        entity.rooms.forEach(roomId => {
            devices = devices.concat(getDevicesFromEntity(state, roomId))
        })
    }

    if (entity.operationPoints) {
        entity.operationPoints.forEach(operationPointId => {
            devices = devices.concat(getDevicesFromEntity(state, operationPointId))
        })
    }

    if (entity.devices) {
        entity.devices.forEach(deviceId => {
            const device = getEntity(state, deviceId)

            if (!devices.find(el => el === device.availableDeviceId)) {
                devices.push(device.availableDeviceId)
            }
        })
    }

    return devices
}

const getAffectedEntities = (entity, affectedEntities = []) => {
    if (['building', 'floor', 'room'].includes(entity.type)) {
        if (entity.type !== 'building') {
            affectedEntities.push(entity.id)
        }
        let subEntities = entity.type === 'building'
            ? entity.floors
            : entity.type === 'floor'
                ? entity.rooms
                : entity.operationPoints
        subEntities.forEach(el => {
            affectedEntities = affectedEntities.concat(getAffectedEntities(el, affectedEntities))
        })
    }

    if (entity.type === 'operationPoint') {
        affectedEntities.push(entity.id)
    }
    return affectedEntities.filter((x, i, a) => a.indexOf(x) === i)
}
