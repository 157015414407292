import { calculatePossibleDevice } from "./calculatePossibleDevice"

export const calculateFrames = (deviceList, serieId, frameSize, frameColor, orientation, frameMapping) => {
    if (frameSize === 0) {
        return []
    }
    
    const articleColor = frameMapping.find(el => {
        return el.serie === serieId && el.colorId === frameColor
    })?.articleColor

    let description = {    
        feature: "EC000007",
        filters: [
            {
                Id: "EF001035",
                Value: "false"
            }
        ]
    }
    
    if (articleColor) {
        description.manufacturerTypeDescr = "-" + articleColor
    }

    if (frameSize) {
        description.filters.push({
            Id: 'EF012040',
            Value: frameSize,
        })
    }

    if ((serieId === 27 || serieId === 61) && orientation === 'H') {
        description.filters.push({
            Id: "EF003824",
            Value: "EV002314"
        })
    } else if ((serieId === 27 || serieId === 61) && orientation === 'V') {
        description.filters.push({
            Id: "EF003824",
            Value: "EV001302"
        })
    } else {
        description.filters.push({
            Id: "EF003824",
            Value: "EV000130"
        })
    }
    const devices = calculatePossibleDevice(deviceList, [description], undefined, serieId)

    return devices.filter(device => {
        let match = true;
        let features = JSON.parse(device.features)
        const klappDeckel = features[0].Feature.find(feature => feature.Fname === 'EF000117')
        if (klappDeckel) {
            match = klappDeckel.Fvalue !== 'true'
        }
        if (match
            && ((serieId !== 59 && serieId !== 53)
                || ((serieId === 59 || serieId === 53) && device.manufacturerTypeDescr.includes(frameColor+'K') === false
            ))
        ) {
            if (!device.manufacturerTypeDescr.includes('KA-')) {
                features.forEach(featureClass => {
                    if (featureClass.Id === 'EC000007') {
                        featureClass.Feature.forEach(feature => {
                            description.filters.forEach(filter => {
                                if (filter.Id === feature.Fname) {
                                    if (filter.Value.toString() !== feature.Fvalue.toString()) {
                                        match = false
                                    }
                                }
                            })
                        })
                    }
                })
                
            }
        }
        return match
    })
}

export const calculateFrame = (deviceList, serieId, frameSize, frameColor, orientation, frameMapping) => {
    const devices = calculateFrames(deviceList, serieId, frameSize, frameColor, orientation, frameMapping)
    if (devices?.length > 0) {
        return devices[0]
    }
    return null
}

export const calulatePossibleFrameSizes = (deviceList, serieId, frameSize, frameColor, orientation, frameMapping) => {
    const devices = calculateFrames(deviceList, serieId, frameSize, frameColor, orientation, frameMapping)
    if (Array.isArray(devices)) {
        return devices.map(device => {
            let size = undefined
            let features = JSON.parse(device.features)
            features.forEach(featureClass => {
                if (featureClass.Id === 'EC000007') {
                    featureClass.Feature.forEach(feature => {
                        if (feature.Fname === 'EF012040') {
                            size = parseInt(feature.Fvalue)
                        }
                    })
                }
            })
            return size
        }).filter(size => size).sort()
    }
    return []
}