import {v4} from 'node-uuid'
import {batchActions} from 'redux-batched-actions'
import { omit } from 'lodash'
import { createSlice } from '@reduxjs/toolkit'
import {createSelector} from 'reselect'
import {clearEntities, setEntities} from '../entities/duck'
import {createBuilding} from '../entities/building/duck'
import {createFloor} from '../entities/floor/duck'
import {createRoom} from '../entities/room/duck'
import { migration } from '../../vendor/migration'

export const FETCH_PROJECTS_REQUEST = 'raumplaner/projects/REQUEST'
export const FETCH_PROJECTS_FAILURE = 'raumplaner/projects/FAILURE'
export const FETCH_PROJECTS_RECEIVE = 'raumplaner/projects/RECEIVE'

export const CREATE_PROJECT = 'raumplaner/projects/CREATE_PROJECT'
export const SET_PROJECT_EDIT = 'raumplaner/projects/SET_EDIT'
export const SET_PROJECT_TYPE = 'raumplaner/projects/SET_PROJECT_TYPE'
export const SET_PROJECT_PREFERENCES = 'raumplaner/projects/SET_PROJECT_PREFERENCES'
export const SET_PROJECT_DEVICETYPE = 'raumplaner/projects/SET_PROJECT_DEVICETYPE'
export const SET_PROJECT_SERIE = 'raumplaner/projects/SET_PROJECT_SERIE'
export const SET_CPLATE_COLOR = 'raumplaner/projects/SET_CPLATE_COLOR'
export const SET_FRAME_COLOR = 'raumplaner/projects/SET_FRAME_COLOR'
export const SET_ROOM_FLOOR_PLANNING = 'raumplaner/projects/SET_ROOM_FLOOR_PLANNING'



export const initialState = {
    ID: null,
    data: {},
}

const projectsSlice = createSlice({
    name: 'projects',
    initialState,
    reducers: {
        addProject(state, action) {
            state.ID = action.payload.id
            state.data = {
                ...initialState.data,
                rootEntityId: action.payload.rootEntityId,
            }
        },
        addExistingProject(state, action) {
            state.ID = action.payload.ID
            state.data = {
                ...action.payload.data,
            }
        },
        setProjectPreferences(state, action) {
            state.data = {
                ...state.data,
                ...action.payload.projectPreferences,
            }
        },
        setProjectDeviceType(state, action) {
            state.data = {
                ...state.data,
                deviceType: action.payload,
            }
        },
        setProjectSerie(state, action) {
            state.data.serie = action.payload.serie
        },
        setProjectCplateColor(state, action) {
            state.data.cplateColor = action.payload.cplateColor
        },
        setProjectFrameColor(state, action) {
            state.data.frameColor = action.payload.frameColor
        },
        setRoomFloorPlanning(state) {
            state.data.roomFloorPlanning = true
        },
        setProjectId(state, action) {
            state.ID = action.payload.ID
        }
    },
})

export const {
    addProject,
    addExistingProject,
    setProjectPreferences,
    setProjectDeviceType,
    setProjectSerie,
    setProjectCplateColor,
    setProjectFrameColor,
    setRoomFloorPlanning,
    setProjectId
} = projectsSlice.actions
export default projectsSlice.reducer


export const createProject = (id = 'local') => {
    return (dispatch) => {
        let rootEntityId = v4()
        let floorId = v4()
        let roomId = v4()
        
        dispatch(batchActions([
            clearEntities(),
            createBuilding(rootEntityId),
            createFloor(rootEntityId, floorId),
            createRoom(floorId, roomId),
            addProject({id, rootEntityId}),
        ]))
    }
}

export const loadProject = project => {
    return (dispatch, getState) => {
        const state = getState()
        const data = JSON.parse(project.data)
        const projectToLoad = {
            ID: project.ID,
            data: {
                ...omit(data, ['entities']),
            },
        }

        const newState = migration({
            ...state,
            projects: projectToLoad,
            entities: { allIds: Object.keys(data.entities), byId: data.entities },
        })
        dispatch(batchActions([addExistingProject(projectToLoad), setEntities(newState.entities.byId)]))
    }
}

export const getEditableProject = state => state.projects
export const getEditProject = state => state.projects.ID
export const getProjectDeviceType = state => state.projects?.data?.deviceType || 'conventional'

export const getDesignInformationFromProject = createSelector(
    [getEditableProject],
    (project) => {
        return {
            frameRangeId: project.data.serie,
            frameColorId: project.data.frameColor,
            switchColorId: project.data.cplateColor,
            touchProtection: project.data.touchProtection,
            dataSockets: project.data.dataSockets,
            satSockets: project.data.satSockets,
            deviceType: project.data.deviceType
        }
    }
)

export const getIsRoomFloorPlanning = createSelector([getEditableProject], project => project.data.roomFloorPlanning)

export const defaultDeviceType = "conventional"