import { Machine, assign } from 'xstate'

const initialValues = {
    type: 'WECHSELSCHALTER',
    channel: 'CHANNEL_NONE',
    rocker: 'ROCKER_NONE',
    voltage: 'VOLTAGE_NONE',
    light: 'LIGHT_NONE',
    specials: 'SPECIALS_NONE',
}

export const calculate = (context, event, actionMeta) => {
    let value = JSON.parse(JSON.stringify(actionMeta.state?.value || initialValues))
    if (event?.payload?.identifier) {
        value[event.payload.identifier] = event.type
    }
    const { type, channel, rocker, light, voltage, specials } = value
    let notPossible = false

    let formStatus = {}
    Object.keys(switchMachine.states).forEach(key => {
        formStatus[key] = {
            open: key === 'type' ? true : false,
        }

        if (key === 'type') {
            formStatus[key].elements = switchMachine.states.type.events.filter(key => {
                return [type, 'TYPE_NONE', 'WECHSELSCHALTER', 'AUSSCHALTER', 'KREUZSCHALTER'].find(id => id === key)
            })
        }
    })

    context = [
        {
            feature: '',
            filters: [
                {
                    Id: 'EF000005',
                    Value: 'EV000078',
                },
            ],
            additionalFilters: [],
        },
    ]

    if (type === 'WECHSELSCHALTER') {
        let filter = {
            Id: 'EF002960',
            Value: ['EV000648', 'EV006530'],
        }
        if (channel === 'SINGLE_KANAL') {
            filter = {
                Id: 'EF002960',
                Value: 'EV000648',
            }
        } else if (channel === 'DOUBLE_KANAL') {
            filter = {
                Id: 'EF002960',
                Value: 'EV006530',
            }
        } else if (channel === 'TRIPPLE_KANAL') {
            notPossible = true
        }

        if (!notPossible) {
            context[0].feature = 'EC001590'
        }
        context[0].filters.push(filter)
    } else if (type === 'AUSSCHALTER') {
        let filters = []
        if (channel === 'SINGLE_KANAL') {
            // 2733 US-101 müsste möglich sein
            notPossible = true
        } else if (channel === 'DOUBLE_KANAL') {
            if (rocker === 'DOUBLE_ROCKER') {
                filters.push({
                    Id: 'EF002960',
                    Value: 'EV000943',
                })
            } else if (rocker === 'SINGLE_ROCKER') {
                filters.push({
                    Id: 'EF002960',
                    Value: 'EV000500',
                })
            } else {
                filters.push({
                    Id: 'EF002960',
                    Value: ['EV000500', 'EV000500'],
                })
            }
        } else if (channel === 'TRIPPLE_KANAL') {
            filters.push({
                Id: 'EF002960',
                Value: 'EV006374',
            })
        } else if (channel === 'CHANNEL_NONE') {
            filters.push({
                Id: 'EF002960',
                Value: ['EV000943', 'EV000500', 'EV006374'],
            })
        }
        if (!notPossible) {
            context[0].feature = 'EC001590'
        }
        context[0].filters = context[0].filters.concat(filters)
    } else if (type === 'KREUZSCHALTER') {
        if (channel === 'DOUBLE_KANAL' || channel === 'TRIPPLE_KANAL') {
            notPossible = true
        }

        if (!notPossible) {
            context[0].feature = 'EC001590'
        }
        context[0].filters.push({
            Id: 'EF002960',
            Value: 'EV006435',
        })
    } else if (type === 'DREI_STUFEN_MIT_NULLSTELLUNG') {
        if (channel === 'SINGLE_KANAL' || channel === 'DOUBLE_KANAL' || channel === 'TRIPPLE_KANAL') {
            notPossible = true
        }

        if (!notPossible) {
            context[0].feature = 'EC001036'
        }
        context[0].filters.push({
            // Filter nach eigener DB
            Id: 'EF000006',
            Value: 'EV000154',
        })
    } else if (type === 'KOMFORTSCHALTER') {
        if (channel === 'SINGLE_KANAL' || channel === 'DOUBLE_KANAL' || channel === 'TRIPPLE_KANAL') {
            notPossible = true
        }

        if (!notPossible) {
            context[0].feature = ['EC001035', 'EC001588']
        }
        context[0].filters = context[0].filters.filter(el => el.Id !== 'EF000005')
    } else if (type === 'DREI_STUFEN_OHNE_NULLSTELLUNG') {
        if (channel === 'SINGLE_KANAL' || channel === 'DOUBLE_KANAL' || channel === 'TRIPPLE_KANAL') {
            notPossible = true
        }

        if (!notPossible) {
            context[0].feature = 'EC001036'
        }
        context[0].filters.push({
            // Filter nach eigener DB
            Id: 'EF000006',
            Value: 'EV000092',
        })
    } else if (type === 'JALOUSIESCHALTER') {
        if (channel === 'TRIPPLE_KANAL') {
            notPossible = true
        }

        if (!notPossible) {
            context[0].feature = 'EC000044'
        }
        context[0].filters.push({
            Id: 'EF000010',
            Value: ['EV000499', 'EV000500', 'EV000509', 'EV000510', 'EV006509', 'EV006510'],
        })
    } else if (type === 'JALOUSIESCHALTER_DREHKNOPF') {
        if (channel === 'TRIPPLE_KANAL') {
            notPossible = true
        }

        if (!notPossible) {
            context[0].feature = 'EC000044'
        }
        context[0].filters.push({
            Id: 'EF000006',
            Value: 'EV000092',
        })
        context[0].filters.push({
            Id: 'EF000010',
            Value: ['EV000499', 'EV000500', 'EV000509', 'EV000510', 'EV006509', 'EV006510'],
        })
    } else if (type === 'PROFILHALBZYLINDER_SCHALTER') {
        if (channel === 'TRIPPLE_KANAL') {
            notPossible = true
        }

        if (!notPossible) {
            //Achtung kann aber auch EC001590 sein, muss noch Lösung gesucht werden
            context[0].feature = 'EC000044'
        }
        context[0].filters.push({
            Id: 'EF000006',
            Value: 'EV000175',
        })
        context[0].filters.push({
            Id: 'EF000010',
            Value: ['EV000499', 'EV000500', 'EV000509', 'EV000510', 'EV006509', 'EV006510'],
        })
    } else if (type === 'SCHLIESSER') {
        if (channel === 'TRIPPLE_KANAL') {
            notPossible = true
        }

        if (!notPossible) {
            context[0].feature = 'EC000029'
        }

        if (channel === 'CHANNEL_NONE') {
            context[0].filters.push({
                Id: 'EF000029',
                Value: ['EV000462', 'EV000463'],
            })
        } else if (channel === 'SINGLE_KANAL') {
            context[0].filters.push({
                Id: 'EF000029',
                Value: ['EV000462'],
            })
        } else if (channel === 'DOUBLE_KANAL') {
            context[0].filters.push({
                Id: 'EF000029',
                Value: ['EV000463'],
            })
        }
    } else if (type === 'OEFFNENTASTER') {
        if (channel === 'DOUBLE_KANAL' || channel === 'TRIPPLE_KANAL') {
            notPossible = true
        }

        if (!notPossible) {
            // 2CKA001413A1111 fehlt in ETIM-Daten
            context[0].feature = ''
        }
    } else if (type === 'ZUGTASTER') {
        if (channel === 'DOUBLE_KANAL' || channel === 'TRIPPLE_KANAL') {
            notPossible = true
        }

        if (!notPossible) {
            // 2CKA001413A1111, 2CKA001413A1112 fehlen in ETIM-Daten
            context[0].feature = ''
        }
    } else if (type === 'WECHSELTASTER') {
        if (channel === 'DOUBLE_KANAL' || channel === 'TRIPPLE_KANAL') {
            notPossible = true
        }

        if (!notPossible) {
            context[0].feature = 'EC000029'
        }
        context[0].filters.push({
            Id: 'EF000029',
            Value: 'EV000464',
        })
    } else if (type === 'JALOUSIETASTER') {
        if (channel === 'TRIPPLE_KANAL') {
            notPossible = true
        }

        if (!notPossible) {
            context[0].feature = 'EC000044'
        }
        context[0].filters.push({
            Id: 'EF000010',
            Value: ['EV000502', 'EV000503'],
        })
    } else if (type === 'JALOUSIETASTER_DREHTASTER') {
        if (channel === 'TRIPPLE_KANAL') {
            notPossible = true
        }

        if (!notPossible) {
            context[0].feature = 'EC000044'
        }
        context[0].filters.push({
            Id: 'EF000006',
            Value: 'EV000092',
        })
        context[0].filters.push({
            Id: 'EF000010',
            Value: ['EV000502', 'EV000503'],
        })
    } else if (type === 'PROFILHALBZYLINDER_TASTER') {
        if (channel === 'TRIPPLE_KANAL') {
            notPossible = true
        }

        if (!notPossible) {
            context[0].feature = 'EC000044'
        }
        context[0].filters.push({
            Id: 'EF000006',
            Value: 'EV000175',
        })
        context[0].filters.push({
            Id: 'EF000010',
            Value: ['EV000502', 'EV000503'],
        })
    }

    if (rocker === 'SINGLE_ROCKER') {
        context[0].filters.push({
            Id: 'EF003816',
            Value: '1',
        })
    } else if (rocker === 'DOUBLE_ROCKER') {
        context[0].filters.push({
            Id: 'EF003816',
            Value: '2',
        })
    }

    if (voltage === 'VOLTAGE_TEN_A') {
        context[0].filters.push({
            Id: 'EF000227',
            Value: '10',
        })
    } else if (voltage === 'VOLTAGE_TEN_AX') {
        context[0].filters.push({
            Id: 'EF003888',
            Value: '10',
        })
    } else if (voltage === 'VOLTAGE_SIXTEEN_A') {
        context[0].filters.push({
            Id: 'EF000227',
            Value: '16',
        })
    } else if (voltage === 'VOLTAGE_SIXTEEN_AX') {
        context[0].filters.push({
            Id: 'EF003888',
            Value: '16',
        })
    }

    if (light === 'KONTROLLLEUCHTE') {
        context[0].filters.push({
            Id: 'EF000134',
            Value: 'EV004711',
        })
        context[0].filters.push({
            Id: 'EF009420',
            Value: 'EV011600',
        })
    } else if (light === 'OERIENTIERUNGSLICHT') {
        context[0].filters.push({
            Id: 'EF000134',
            Value: 'EV004711',
        })
        context[0].filters.push({
            Id: 'EF009420',
            Value: ['EV011600', 'EV006466'],
        })
    } else if (light === 'N_KLEMME') {
        context[0].additionalFilters.push('n_klemme')
    } else if (light === 'SEP_KLEMME') {
        context[0].additionalFilters.push('sep_klemme')
    }

    if (specials === 'SEP_EINSPEISUNG') {
        context[0].additionalFilters.push('sep_einspeisung')
    }

    return {
        descriptions: context,
        formStatus,
    }
}

export const switchMachine = Machine(
    {
        id: 'switch',
        type: 'parallel',
        context: {},
        states: {
            type: {
                initial: initialValues.type,
                on: {
                    //TYPE_NONE: 'type.TYPE_NONE',
                    WECHSELSCHALTER: 'type.WECHSELSCHALTER',
                    AUSSCHALTER: 'type.AUSSCHALTER',
                    KREUZSCHALTER: 'type.KREUZSCHALTER',
                    DREI_STUFEN_MIT_NULLSTELLUNG: 'type.DREI_STUFEN_MIT_NULLSTELLUNG',
                    KOMFORTSCHALTER: 'type.KOMFORTSCHALTER',
                    DREI_STUFEN_OHNE_NULLSTELLUNG: 'type.DREI_STUFEN_OHNE_NULLSTELLUNG',
                    JALOUSIESCHALTER: 'type.JALOUSIESCHALTER',
                    JALOUSIESCHALTER_DREHKNOPF: 'type.JALOUSIESCHALTER_DREHKNOPF',
                    PROFILHALBZYLINDER_SCHALTER: 'type.PROFILHALBZYLINDER_SCHALTER',
                    SCHLIESSER: 'type.SCHLIESSER',
                    OEFFNENTASTER: 'type.OEFFNENTASTER',
                    ZUGTASTER: 'type.ZUGTASTER',
                    WECHSELTASTER: 'type.WECHSELTASTER',
                    JALOUSIETASTER: 'type.JALOUSIETASTER',
                    JALOUSIETASTER_DREHTASTER: 'type.JALOUSIETASTER_DREHTASTER',
                    PROFILHALBZYLINDER_TASTER: 'type.PROFILHALBZYLINDER_TASTER',
                },
                states: {
                    /*TYPE_NONE: {
                    entry: ['calculate'],
                },*/
                    WECHSELSCHALTER: {
                        entry: ['calculate'],
                    },
                    AUSSCHALTER: {
                        entry: ['calculate'],
                    },
                    KREUZSCHALTER: {
                        entry: ['calculate'],
                    },
                    DREI_STUFEN_MIT_NULLSTELLUNG: {
                        entry: ['calculate'],
                    },
                    KOMFORTSCHALTER: {
                        entry: ['calculate'],
                    },
                    DREI_STUFEN_OHNE_NULLSTELLUNG: {
                        entry: ['calculate'],
                    },
                    JALOUSIESCHALTER: {
                        entry: ['calculate'],
                    },
                    JALOUSIESCHALTER_DREHKNOPF: {
                        entry: ['calculate'],
                    },
                    PROFILHALBZYLINDER_SCHALTER: {
                        entry: ['calculate'],
                    },
                    OEFFNENTASTER: {
                        entry: ['calculate'],
                    },
                    ZUGTASTER: {
                        entry: ['calculate'],
                    },
                    SCHLIESSER: {
                        entry: ['calculate'],
                    },
                    WECHSELTASTER: {
                        entry: ['calculate'],
                    },
                    JALOUSIETASTER: {
                        entry: ['calculate'],
                    },
                    JALOUSIETASTER_DREHTASTER: {
                        entry: ['calculate'],
                    },
                    PROFILHALBZYLINDER_TASTER: {
                        entry: ['calculate'],
                    },
                },
            },
            channel: {
                initial: initialValues.channel,
                on: {
                    CHANNEL_NONE: 'channel.CHANNEL_NONE',
                    SINGLE_KANAL: 'channel.SINGLE_KANAL',
                    DOUBLE_KANAL: 'channel.DOUBLE_KANAL',
                    TRIPPLE_KANAL: 'channel.TRIPPLE_KANAL',
                },
                states: {
                    CHANNEL_NONE: {
                        entry: ['calculate'],
                    },
                    SINGLE_KANAL: {
                        entry: ['calculate'],
                    },
                    DOUBLE_KANAL: {
                        entry: ['calculate'],
                    },
                    TRIPPLE_KANAL: {
                        entry: ['calculate'],
                    },
                },
            },
            rocker: {
                initial: 'ROCKER_NONE',
                on: {
                    ROCKER_NONE: 'rocker.ROCKER_NONE',
                    SINGLE_ROCKER: 'rocker.SINGLE_ROCKER',
                    DOUBLE_ROCKER: 'rocker.DOUBLE_ROCKER',
                },
                states: {
                    ROCKER_NONE: {
                        entry: ['calculate'],
                    },
                    SINGLE_ROCKER: {
                        entry: ['calculate'],
                    },
                    DOUBLE_ROCKER: {
                        entry: ['calculate'],
                    },
                },
            },
            voltage: {
                initial: initialValues.voltage,
                on: {
                    VOLTAGE_NONE: 'voltage.VOLTAGE_NONE',
                    VOLTAGE_TEN_A: 'voltage.VOLTAGE_TEN_A',
                    VOLTAGE_TEN_AX: 'voltage.VOLTAGE_TEN_AX',
                    VOLTAGE_SIXTEEN_A: 'voltage.VOLTAGE_SIXTEEN_A',
                    VOLTAGE_SIXTEEN_AX: 'voltage.VOLTAGE_SIXTEEN_AX',
                },
                states: {
                    VOLTAGE_NONE: {
                        entry: ['calculate'],
                    },
                    VOLTAGE_TEN_A: {
                        entry: ['calculate'],
                    },
                    VOLTAGE_TEN_AX: {
                        entry: ['calculate'],
                    },
                    VOLTAGE_SIXTEEN_A: {
                        entry: ['calculate'],
                    },
                    VOLTAGE_SIXTEEN_AX: {
                        entry: ['calculate'],
                    },
                },
            },
            light: {
                initial: initialValues.light,
                on: {
                    LIGHT_NONE: 'light.LIGHT_NONE',
                    KONTROLLLEUCHTE: 'light.KONTROLLLEUCHTE',
                    N_KLEMME: 'light.N_KLEMME',
                    SEP_KLEMME: 'light.SEP_KLEMME',
                    OERIENTIERUNGSLICHT: 'light.OERIENTIERUNGSLICHT',
                },
                states: {
                    LIGHT_NONE: {
                        entry: ['calculate'],
                    },
                    KONTROLLLEUCHTE: {
                        entry: ['calculate'],
                    },
                    N_KLEMME: {
                        entry: ['calculate'],
                    },
                    SEP_KLEMME: {
                        entry: ['calculate'],
                    },
                    OERIENTIERUNGSLICHT: {
                        entry: ['calculate'],
                    },
                },
            },
            specials: {
                initial: initialValues.specials,
                on: {
                    SPECIALS_NONE: 'specials.SPECIALS_NONE',
                    SEPERATE_EINSPEISUNG: 'specials.SEPERATE_EINSPEISUNG',
                },
                states: {
                    SPECIALS_NONE: {
                        entry: ['calculate'],
                    },
                    SEPERATE_EINSPEISUNG: {
                        entry: ['calculate'],
                    },
                },
            },
        },
    },
    {
        actions: {
            calculate: assign(calculate),
        },
    }
)

export const possibleSwitchClasses = {
    classes: ['EC000029', 'EC000044', 'EC000615', 'EC001035', 'EC001588', 'EC001590'],
    types: {
        'WECHSELSCHALTER': switchMachine.initialState?.context?.descriptions,
        'AUSSCHALTER': switchMachine.transition(switchMachine.initialState, { type: 'AUSSCHALTER', payload: { identifier: 'type'}})?.context?.descriptions,
        'KREUZSCHALTER': switchMachine.transition(switchMachine.initialState, { type: 'KREUZSCHALTER', payload: { identifier: 'type'}})?.context?.descriptions,
        'DREI_STUFEN_MIT_NULLSTELLUNG': switchMachine.transition(switchMachine.initialState, { type: 'DREI_STUFEN_MIT_NULLSTELLUNG', payload: { identifier: 'type'}})?.context?.descriptions,
        'KOMFORTSCHALTER': switchMachine.transition(switchMachine.initialState, { type: 'KOMFORTSCHALTER', payload: { identifier: 'type'}})?.context?.descriptions,
        'DREI_STUFEN_OHNE_NULLSTELLUNG': switchMachine.transition(switchMachine.initialState, { type: 'DREI_STUFEN_OHNE_NULLSTELLUNG', payload: { identifier: 'type'}})?.context?.descriptions,
        'JALOUSIESCHALTER': switchMachine.transition(switchMachine.initialState, { type: 'JALOUSIESCHALTER', payload: { identifier: 'type'}})?.context?.descriptions,
        'JALOUSIETASTER_DREHTASTER': switchMachine.transition(switchMachine.initialState, { type: 'JALOUSIETASTER_DREHTASTER', payload: { identifier: 'type'}})?.context?.descriptions,
        'PROFILHALBZYLINDER_TASTER': switchMachine.transition(switchMachine.initialState, { type: 'PROFILHALBZYLINDER_TASTER', payload: { identifier: 'type'}})?.context?.descriptions,
    }
}

export default switchMachine
