import v4 from 'node-uuid'
import {omit} from 'lodash'
import {batchActions} from 'redux-batched-actions'
import {buildRoomFloorReference} from '../floor/duck'
import {
    getEntityResolved, setConfigValue,
} from '../duck'
import {generateDeleteSequence} from '../helpers/index'

// Actions
//
export const ADD_ROOM = 'raumplaner/entities/ROOM/ADD'
export const CLONE_ROOM = 'raumplaner/entities/ROOM/CLONE'
export const REMOVE_ROOM = 'raumplaner/entities/ROOM/REMOVE'
export const ROOM_REFERENCE_OPERATION_POINT = 'raumplaner/entities/ROOM/REFERENCE_OPERATION_POINT'
export const ROOM_DEREFERENCE_OPERATION_POINT = 'raumplaner/entities/ROOM/DEREFERENCE_OPERATION_POINT'

// Reducer
//
export const roomInitialState = {
    type: 'room',
    id: null,
    parent: null,
    label: null,
    config: [],
    operationPoints: [],
}

function reducer(state = roomInitialState, action) {
    let operationPointIndex
    switch (action.type) {
        case CLONE_ROOM:
            return {
                ...state,
                id: action.roomId,
                parent: action.floorId,
                operationPoints: roomInitialState.operationPoints,
            }
        case ADD_ROOM:
            return {
                ...state,
                id: action.roomId,
                parent: action.floorId,
            }
        case ROOM_REFERENCE_OPERATION_POINT:
            return {
                ...state,
                operationPoints: [...state.operationPoints, action.operationPointId],
            }
        case ROOM_DEREFERENCE_OPERATION_POINT:
            operationPointIndex = state.operationPoints.indexOf(action.operationPointId)
            return {
                ...state,
                operationPoints: [...state.operationPoints.slice(0, operationPointIndex), ...state.operationPoints.slice(operationPointIndex + 1)],
            }
        default:
            return state
    }
}

export default function roomListReducer(state = {byId: {}, allIds: []}, action) {
    let allIdIndex
    switch (action.type) {
        case CLONE_ROOM:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.roomId]: reducer(state.byId[action.originRoomId], action),
                },
                allIds: [...state.allIds, action.roomId],
            }
        case ADD_ROOM:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.roomId]: reducer(state.byId[action.roomId], action),
                },
                allIds: [...state.allIds, action.roomId],
            }
        case REMOVE_ROOM: {
            allIdIndex = state.allIds.indexOf(action.roomId)
            return {
                ...state,
                byId: omit(state.byId, [action.roomId]),
                allIds: [...state.allIds.slice(0, allIdIndex), ...state.allIds.slice(allIdIndex + 1)],
            }
        }
        case ROOM_REFERENCE_OPERATION_POINT:
        case ROOM_DEREFERENCE_OPERATION_POINT:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.roomId]: reducer(state.byId[action.roomId], action),
                },
            }
        default:
            return state
    }
}

// Action Creators
//
export const addRoom = (floorId, roomId) => {
    return {
        type: ADD_ROOM,
        roomId,
        floorId,
    }
}

export const createRoom = (
    floorId,
    roomId = v4(),
    serie,
    frameColor,
    cPlateColor,
) => {
    const sequence = [
        addRoom(floorId, roomId),
        buildRoomFloorReference(floorId, roomId),
    ]

    if (serie) {
        sequence.push(setConfigValue(roomId, {type: 'serie', value: serie}))
    }

    if (frameColor) {
        setConfigValue(roomId, {type: 'frameColor', value: frameColor})
    }

    if (cPlateColor) {
        setConfigValue(roomId, {type: 'cplateColor', value: cPlateColor})
    }

    return batchActions(sequence)
}

export const deleteRoom = roomId => {
    return {
        type: REMOVE_ROOM,
        roomId,
    }
}

export const removeRoom = (room) => {
    return (dispatch, getState) => {
        if (!room.parent.hasOwnProperty('id')) {
            room = getEntityResolved(getState(), room.id)
        }
        return dispatch(generateDeleteSequence(getState(), room, 0))
    }
}

export const referenceOperationPointWithRoom = (roomId, operationPointId) => {
    return {
        type: ROOM_REFERENCE_OPERATION_POINT,
        roomId,
        operationPointId,
    }
}

export const dereferenceOperationPointWithRoom = (roomId, operationPointId) => {
    return {
        type: ROOM_DEREFERENCE_OPERATION_POINT,
        roomId,
        operationPointId,
    }
}


export const buildOperationPointRoomReference = (roomId, OperationPointId) => {
    return referenceOperationPointWithRoom(roomId, OperationPointId)
}

export const removeOperationPointRoomReference = (roomId, OperationPointId) => {
    return dereferenceOperationPointWithRoom(roomId, OperationPointId)
}

export const copyRoom = (floorId, originRoomId, roomId = v4()) => {
    return {
        type: CLONE_ROOM,
        floorId,
        originRoomId,
        roomId,
    }
}

export const cloneRoom = (floorId, originRoomId, roomId = v4()) => {
    return batchActions([copyRoom(floorId, originRoomId, roomId), buildRoomFloorReference(floorId, roomId)])
}
