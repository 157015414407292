import v4 from 'node-uuid'
import {last, omit} from 'lodash'
import {batchActions} from 'redux-batched-actions'

// Actions
//
export const ADD_BUILDING = 'raumplaner/entities/BUILDING/ADD'
export const REMOVE_BUILDING = 'raumplaner/entities/BUILDING/REMOVE'
export const CLONE_BUILDING = 'raumplaner/entities/BUILDING/CLONE'

export const REFERENCE_FLOOR = 'raumplaner/entities/BUILDING/REFERENCE_FLOOR'
export const DEREFERENCE_FLOOR = 'raumplaner/entities/BUILDING/DEREFERENCE_FLOOR'
export const SET_ACTIVE_FLOOR = 'raumplaner/entities/BUILDING/SET_ACTIVE_FLOOR'

// Reducer
//
export const buildingInitialState = {
    id: null,
    type: 'building',
    label: null,
    floors: [],
    activeFloor: null,
}

function reducer(state = buildingInitialState, action) {
    let floorIndex
    let floors
    switch (action.type) {
        case CLONE_BUILDING:
            return {
                ...state,
                id: action.buildingId,
                floors: [],
            }
        case ADD_BUILDING:
            return {
                ...state,
                id: action.buildingId,
            }
        case REFERENCE_FLOOR:
            return {
                ...state,
                floors: [...state.floors, action.floorId],
            }
        case DEREFERENCE_FLOOR:
            floorIndex = state.floors.indexOf(action.floorId)
            floors = [...state.floors.slice(0, floorIndex), ...state.floors.slice(floorIndex + 1)]
            return {
                ...state,
                floors: floors,
                activeFloor: floors[floorIndex] ? floors[floorIndex] : last(floors),
            }
        case SET_ACTIVE_FLOOR:
            return {
                ...state,
                activeFloor: action.floorId,
            }
        default:
            return state
    }
}

export default function buildingListReducer(state = {byId: {}, allIds: []}, action) {
    let allIdIndex
    switch (action.type) {
        case CLONE_BUILDING:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.buildingId]: reducer(state.byId[action.originBuildingId], action),
                },
                allIds: [...state.allIds, action.buildingId],
            }
        case ADD_BUILDING:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.buildingId]: reducer(state.byId[action.buildingId], action),
                },
                allIds: [...state.allIds, action.buildingId],
            }
        case REFERENCE_FLOOR:
        case DEREFERENCE_FLOOR:
        case SET_ACTIVE_FLOOR:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.buildingId]: reducer(state.byId[action.buildingId], action),
                },
            }
        case REMOVE_BUILDING:
            allIdIndex = state.allIds.indexOf(action.buildingId)
            return {
                ...state,
                allIds: [...state.allIds.slice(0, allIdIndex), ...state.allIds.slice(allIdIndex + 1)],
                byId: omit(state.byId, [action.buildingId]),
            }
        default:
            return state
    }
}

// Action Creators
//
export function addBuilding(buildingId) {
    return {
        type: ADD_BUILDING,
        buildingId,
    }
}

export function deleteBuilding(buildingId) {
    return {
        type: REMOVE_BUILDING,
        buildingId,
    }
}

export function removeBuilding(buildingId) {
    return batchActions([deleteBuilding(buildingId)])
}

export function createBuilding(buildingId = v4()) {
    const sequence = [addBuilding(buildingId)]
    return batchActions(sequence)
}

export function referenceFloorWithBuilding(
    buildingId,
    floorId,
) {
    return {
        type: REFERENCE_FLOOR,
        buildingId,
        floorId,
    }
}

export function dereferenceFloorWithBuilding(buildingId, floorId) {
    return {
        type: DEREFERENCE_FLOOR,
        buildingId,
        floorId,
    }
}

export function setActiveFloor(buildingId, floorId) {
    return {
        type: SET_ACTIVE_FLOOR,
        buildingId,
        floorId,
    }
}

export function buildFloorBuildingReference(
    buildingId,
    floorId,
) {
    return batchActions([
        referenceFloorWithBuilding(buildingId, floorId),
        setActiveFloor(buildingId, floorId),
    ])
}

export function removeFloorBuildingReference(buildingId, floorId) {
    return dereferenceFloorWithBuilding(buildingId, floorId)
}

export function copyBuilding(Id, originBuildingId, buildingId = v4()) {
    return {
        type: CLONE_BUILDING,
        originBuildingId,
        buildingId,
    }
}

export function cloneBuilding(originBuildingId, buildingId = v4()) {
    return batchActions([copyBuilding(originBuildingId, buildingId)])
}
