import {v4} from 'node-uuid'
import {batchActions} from 'redux-batched-actions'
import {
    copyFloor,
    deleteFloor,
    dereferenceRoomWithFloor,
    referenceRoomWithFloor,
    setActiveRoom,
} from '../floor/duck'
import {
    copyRoom,
    deleteRoom,
    referenceOperationPointWithRoom,
    dereferenceOperationPointWithRoom,
} from '../room/duck'
import {
    copyOperationPoint,
    deleteOperationPoint,
    dereferenceDeviceWithOperationPoint,
    dereferenceFrameWithOperationPoint,
    getOrientation,
    referenceDeviceWithOperationPoint,
    referenceFrameWithOperationPoint,
} from '../operationPoint/duck'
import {
    addFrameToOpertaionPoint,
    copyDevice,
    deleteDevice,
} from '../device/duck'
import {
    entityAttributes,
} from '../duck'
import {
    dereferenceFloorWithBuilding,
    referenceFloorWithBuilding,
    setActiveFloor,
} from '../building/duck'
import { getEditableProject } from '../../projects/duck'
import { getDeviceList, getFrameMapping } from '../../availableDevices/duck'
import { calculateFrame } from '../../../logic/calculateFrame'

export function generateCloneSequence(state, entity, parentId, batch = 1, layer = 0, targetEntityId) {
    parentId = parentId || entity.parent.id || entity.parent
    let originSubId = entity.id
    let sequence = []
    for (let i = 0; i < batch; i++) {
        const newId = i === 0 && targetEntityId ? targetEntityId : v4()
        if (entity.parent) {
            switch (entity.parent.type + '.' + entity.type) {
                case 'building.floor':
                    sequence.push(copyFloor(parentId, originSubId, newId))
                    sequence.push(referenceFloorWithBuilding(parentId, newId))
                    sequence.push(setActiveFloor(parentId, newId))
                    break
                case 'floor.room':
                    sequence.push(copyRoom(parentId, originSubId, newId, entity.position))
                    sequence.push(referenceRoomWithFloor(parentId, newId))
                    sequence.push(setActiveRoom(parentId, newId))
                    break
                case 'room.operationPoint':
                    sequence.push(copyOperationPoint(parentId, originSubId, newId))
                    sequence.push(referenceOperationPointWithRoom(parentId, newId))
                    break
                case 'operationPoint.device':
                    sequence.push(copyDevice(parentId, originSubId, newId))
                    sequence.push(referenceDeviceWithOperationPoint(parentId, newId))
                    break
                case 'operationPoint.frame':
                    sequence.push(copyDevice(parentId, originSubId, newId))
                    sequence.push(referenceFrameWithOperationPoint(parentId, newId))
                    break
                case 'device.device':
                case 'device.cplate':
                    sequence.push(copyDevice(parentId, originSubId, newId))
                    sequence.push(referenceDeviceWithOperationPoint(parentId, newId))
                    break
                default:
                    /*eslint-disable no-undef,no-console */
                    if (process.env === 'dev') {
                        console.warn('deviceType unhandled: ' + entity.parent.type + '.' + entity.type) //eslint-disable-line
                    }
                    /*eslint-enable no-undef,no-console */
                    break
            }
        }

        for (let x = 0; x < entityAttributes.length; x++) {
            let entityAttribute = entityAttributes[x]
            if (entityAttribute === 'frame') {
                if (entity.hasOwnProperty(entityAttribute) && entity[entityAttribute]) {
                    let subSequence = generateCloneSequence(state, entity[entityAttribute], newId, 1, layer + 1)
                    sequence = sequence.concat(subSequence)
                }
            } else if (entity.hasOwnProperty(entityAttribute) && entity[entityAttribute].length > 0) {
                //eslint-disable-next-line no-loop-func
                entity[entityAttribute].map(subEntity => {
                    let subSequence = generateCloneSequence(state, subEntity, newId, 1, layer + 1)
                    sequence = sequence.concat(subSequence)
                    return subEntity
                })
            }
        }
    }

    return sequence
}

export const generateDeleteSequence = (state, entity, layer = 0) => {
    let actionSequence = []
    let subSequence

    const project = getEditableProject(state)
    const deviceList = getDeviceList(state)
    const frameMapping = getFrameMapping(state)
    
    switch (entity.type) {
        case 'floor':
            if (entity.rooms) {
                entity.rooms.map(room => {
                    subSequence = generateDeleteSequence(state, room, layer + 1)
                    actionSequence = actionSequence.concat(subSequence)
                    return room
                })
            }
            actionSequence.push(dereferenceFloorWithBuilding(entity.parent.id, entity.id))
            actionSequence.push(deleteFloor(entity.id))
            break
        case 'room':
            if (entity.operationPoints) {
                entity.operationPoints.map(operationPoint => {
                    subSequence = generateDeleteSequence(state, operationPoint, layer + 1)
                    actionSequence = actionSequence.concat(subSequence)
                    return operationPoint
                })
            }
            actionSequence.push(dereferenceRoomWithFloor(entity.parent.id, entity.id))
            actionSequence.push(deleteRoom(entity.id))
            break
        case 'operationPoint':
            if (entity.frame) {
                subSequence = generateDeleteSequence(state, entity.frame, layer + 1)
                actionSequence = actionSequence.concat(subSequence)
            }
            if (entity.devices) {
                entity.devices.map(device => {
                    subSequence = generateDeleteSequence(state, device, layer + 1)
                    actionSequence = actionSequence.concat(subSequence)
                    return device
                })
            }
            actionSequence.push(dereferenceOperationPointWithRoom(entity.parent.id, entity.id))
            actionSequence.push(deleteOperationPoint(entity.id))
            break
        case 'device':
        case 'cplate':
        case 'frame':
            if (entity.devices) {
                entity.devices.map(device => {
                    subSequence = generateDeleteSequence(state, device, layer + 1)
                    actionSequence = actionSequence.concat(subSequence)
                    return device
                })
            }
            actionSequence.push(dereferenceDeviceWithOperationPoint(entity.parent.id, entity.id))
            actionSequence.push(deleteDevice(entity.id))
            if (entity?.parent?.type === 'operationPoint' && entity.type === 'device' && layer === 0) {
                const frame = calculateFrame(
                    deviceList,
                    project?.data?.serie,
                    entity?.parent?.devices.length? entity?.parent.devices.length - 1 : 0,
                    project?.data?.frameColor,
                    getOrientation(entity?.parent),
                    frameMapping
                )

                if (entity?.parent?.frame) {
                    actionSequence.push(dereferenceFrameWithOperationPoint(entity.parent.id, entity.parent.frame))
                    actionSequence.push(deleteDevice(entity.parent.frame))
                }

                if (frame) {
                    actionSequence.push(addFrameToOpertaionPoint(frame ? frame.supplierPid : null, entity?.parent?.id))
                }
            }
            break
        default:
            break
    }
    if (layer === 0) {
       return batchActions(actionSequence)
    }

    return actionSequence
}
