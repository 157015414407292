import { calculatePossibleDevice } from "./calculatePossibleDevice"

export const calculateDeviceForCPlate = (supplierPid, deviceList, features, articleColor, additionalData, additionalArticles, orientation, serieId) => {
    const originDevice = deviceList.find(el => el.getSupplierpid() === supplierPid)
    
    if (!additionalArticles) {
        additionalArticles = []
    }

    additionalArticles = additionalArticles.map(id => {
        return deviceList.find(el => el.getManufacturertypedescr() === id)
    })

    const additionalArticleData = additionalData.find(el => el.supplier_pid === supplierPid)

    let descriptions = []
    let description = {
        feature: 'EC000011',
        filters: [],
    }

    if (articleColor) {
        description.manufacturerTypeDescr = "-" + articleColor
    }

    const noDevice = [
        'Ankaro',
        'Hirschmann'
    ]

    const notPossibleAdditionalArticles = [
        '2CKA006800A2888',
        '2CKA006800A3041',
        '2CKA006800A3047',
        '2CKA006800A3044',
        '2CKA006500A0012',
        '2CKA006800A3050'
    ]
    const possibleForAddionalArticles = !notPossibleAdditionalArticles.find(id =>  {
        return !!additionalArticles.find(el => el.getSupplierpid() === id)
    })

    const featureClass = possibleForAddionalArticles
        ? features.find(feature => {
            return feature.Id.includes('EC') || noDevice.find(id => id === feature.Id)
        })
        : null

    if (featureClass) {
        switch (featureClass.Id) {
            case "Ankaro":
                description.feature = "EC000018"
				description.filters.push({Id: "EF000133", Value: "EV003629"})
                break;
            case "Hirschmann":
                description.feature = "EC000018"
				description.filters.push({Id: "EF000133", Value: "EV003629"})
                break;
            case "EC000044":
                description.filters.push({Id: "EF000133", Value: "EV003624"})
                break
            case "EC000615":
                    description.filters.push({Id: "EF000133", Value: "EV003624"})
                    description.filters.push({Id: "EF000010", Value: "-"})
                    description.filters.push({Id: "EF003823", Value: "EV006500"})
                break
            case "EC001264":
                description.feature = "EC000018"
                break
            case "EC000439":
                description.feature = "EC000018"
				description.filters.push({Id: "EF000133", Value: "EV003629"})
                break
            case "EC000018":
                description.feature = "EC000018"
                //description.filters.push({Id: "EF000005", Value: "EV000319"})
                description.filters.push({Id: "EF005709", Value: "false"})
                break
            case "EC001588":

                if (additionalArticles?.length === 0) {
                    description.feature = "EC000138"
                    description.filters.push({Id: "EF000010", Value: "EV004048"})
                    description.filters.push({Id: "EF003904", Value: "true"})
                    description.filters.push({Id: "EF003898", Value: "false"})
                    //description.filters.push({Id: "EF001198", Value: "false"})
                    /*description.filters.push({Id: "EF003808", Value: "true"})
                    description.filters.push({Id: "EF003898", Value: "false"})*/
                }
                break
            case "EC001035":
                description.feature = "EC000138"
				description.filters.push({Id: "EF000010", Value: "EV004048"})
                description.filters.push({Id: "EF003904", Value: "true"})
                description.filters.push({Id: "EF003898", Value: "false"})
                //description.filters.push({Id: "EF003808", Value: "true"})
				//description.filters.push({Id: "EF003898", Value: "false"})
                break
            case "EC010927":
                description.feature = "EC000011"
                description.filters.push({Id: "EF000010", Value: "-"})
                description.filters.push({Id: "EF000133", Value: "EV000154"})
                break
            case "EC011309":
                description.feature = "EC000011"
                description.filters.push({Id: "EF000010", Value: "-"})
                description.filters.push({Id: "EF000133", Value: "EV001731"})
                break                
            default:
                break
        }
        if (featureClass.Feature) {
            featureClass.Feature.forEach(feature => {
                switch(feature.Fname) {
                    case "EF000006":
                        if (featureClass.Id === "EC000025"
                            && (
                                feature.Fvalue === "EV008969"
                                || feature.Fvalue === "EV000554"
                            )
                        ) {
                            featureClass.Feature.forEach(feature2 => {
                                if (feature2.Fname ===  "EF003889") {
                                    if (feature2.Fvalue ===  "true") {
                                        description.filters.push({Id: "EF000133", Value: "EV000944"})
                                        description.filters.push({Id: "EF000010", Value: "EV007981"})
                                    } else {
                                        if (additionalArticleData?.zigbee) {
                                            description.feature = 'EC000436'
                                            description.filters = [
                                                {Id: "EF001178", Value: "true"},
                                                {Id: "EF002943", Value: "true"},
                                            ]
                                        } else {
                                            description.filters.push({Id: "EF000133", Value: "EV006477"})
                                        }    
                                    }
                                }
                            })
                        } else if (featureClass.Id ===  "EC001590"
                            || featureClass.Id ===  "EC000029"
                            || featureClass.Id === "EC001036"
                            || featureClass.Id === "EC000044"
                            || featureClass.Id === "EC000534"
                            || featureClass.Id === "EC000025"
                        ) {
                            if (feature.Fvalue === "EV006531"
                                || feature.Fvalue === "EV000200"
                            ) {
                                featureClass.Feature.forEach(feature2 => {
                                    if (feature2.Fname === "EF003816") {
                                        if (feature2.Fvalue === "1") {
                                            description.filters.push({Id: "EF000010", Value: "EV000316"})
                                        } else if (feature2.Fvalue === "2") {
                                            description.filters.push({Id: "EF000010", Value: "EV006540"})
                                        } else if (feature2.Fvalue === "3") {
                                            description.filters.push({Id: "EF000010", Value: "EV006375"})
                                        }
                                    }
                                })
                                if (featureClass.Id === "EC000044") {
                                    description.filters.push({Id: "EF000010", Value: "EV006540"})
                                }
                            } else if (feature.Fvalue === "EV000092"
                                || feature.Fvalue === "EV000175"
                                || feature.Fvalue === "EV000239"
                            ) {
                                description.filters.push({Id: "EF000010", Value: feature.Fvalue})
                                if (featureClass.Id === "EC001036"
                                    && feature.Fvalue === "EV000092"
                                ) {
                                    description.filters.push({Id: "EF003823", Value: "EV006499"})
                                } else if (featureClass.Id === "EC000044"
                                    && feature.Fvalue === "EV000092"
                                ) {
                                    description.filters.push({Id: "EF003823", Value: "EV006500"})
                                }

                                if (featureClass.Id === "EC000534"
                                    || featureClass.Id === "EC000025"
                                ) {
                                    description.filters.push({Id: "EF000133", Value: "EV000944"})
                                }
                            } else if (featureClass.Id === "EC001036"
                                && feature.Fvalue === "EV000154"
                            ) {
                                description.filters.push({Id: "EF000010", Value: "EV000092"})
                                description.filters.push({Id: "EF003823", Value: "EV006499"})

                            } else if (feature.Fvalue ===  "EV000251") {
                                description.filters.push({Id: "EF000010", Value: "EV000092"})

                                if (featureClass.Id ===  "EC000534"
                                    || featureClass.Id ===  "EC000025"
                                ) {
                                    description.filters.push({Id: "EF000133", Value: "EV000944"})
                                }
                            }
                        } else if (featureClass.Id === "EC000044") {
                            if (feature.Fvalue === "EV000200") {
                                description.filters.push({Id: "EF000010", Value: "EV006540"})
                            }
                        }
                        break
                    case "EF000134":
                        //Etim Daten scheinen hier nicht richtig zu passen deswegen muss diese Abfrage raus
                        /*if (feature.Fvalue === "EV004711") {
                            description.filters.push({Id: "EF003864", Value: "true"})
                        } else */ if (feature.Fvalue === "EV004175") {
                            description.filters.push({Id: "EF003864", Value: "false"})
                        }
                        break
                    case "EF003829":
                        if (feature.Fvalue === "true") {
                            description.filters.push({Id: "EF003864", Value: "true"})
                        } else if (feature.Fvalue === "false") {
                            description.filters.push({Id: "EF003864", Value: "false"})
                        }
                        break
                    case "EF000538":
                        if (featureClass.Id === "EC000439") {
                            if (feature.Fvalue === "2") {
                                description.filters.push({Id: "EF001579", Value: "3"})
                            } else {
                                description.filters.push({Id: "EF001579", Value: feature.Fvalue})
                            }
                        }
                        break
                    case "EF000133":
                        if (featureClass.Id === "EC000018") {
                            if (feature.Fvalue === "EV000377") {
                                description.filters.push({Id: "EF000133", Value: "EV000378"})
                                //description.filters.push({Id: "EF000005", Value: "EV006351"})
                                description.filters.push({Id: "EF001579", Value: "0"})

                            }
                        }
                        break
                    case "EF002935":
                        if (featureClass.Id === "EC001264") {
                            if (feature.Fvalue === "EV000154") {
                                description.filters.push({Id: "EF000133", Value: "EV000378"})
                                //description.filters.push({Id: "EF000005", Value: "EV006351"})
                                description.filters.push({Id: "EF001579", Value: "0"})
                            } else {
                                description.filters.push({Id: "EF000133", Value: "EV004378"})
                                featureClass.Feature.forEach(feature2 => {
                                    if (feature2.Fname === "EF001579") {
                                        description.filters.push({Id: "EF001579", Value: feature2.Fvalue})
                                    }
                                })
                            }
                        }
                        break
                    default:
                        break
                }
            })
        }

        if (additionalArticles.length > 0) {
            additionalArticles.forEach(el => {
                if (el) {
                    const additionalDeviceFeatures =  JSON.parse(el.getFeatures())
                    if (additionalDeviceFeatures[0].Id === 'EC000205' || additionalDeviceFeatures[0].Id === 'EC000011') {
                        description.feature = "EC002253"
                        description.filters = []
                        description.filters.push({Id: "EF001499", Value: "false"})
                        //description.filters.push({Id: "EF003811", Value: "EV011934"})
                    } else if (additionalDeviceFeatures[0].Id === 'EC001588') {
                        description.feature = null
                        description.filters = []
                    }
                }
            })
        }

        if (description.feature) {
            descriptions.push(description)
        }

        /*if (featureClass.Id === "EC000025") {
            featureClass.Feature.forEach(feature => {
                if (feature.Fname === "EF003908" && feature.Fvalue === "true") {
                    featureClass.Feature.forEach(feature2 => {
                        if (feature2.Fname === "EF003889" && feature2.Fvalue === "false") {
                            description = {
                                feature: 'EC000928',
                                manufacturerTypeDescr: "-" + articleColor,
                                filters: [],
                            }
                            description.filters.push({Id: "EF001171", Value: "true"})
                            descriptions = [description]
                        }
                    })
                }
            })
        }*/
    }

    let possibleDevices = []

    if (featureClass) {
        if (featureClass.Id !== "EC000125" ) {
            let devices = descriptions.length > 0
                ? calculatePossibleDevice(deviceList, descriptions, additionalData, serieId)
                : []

            possibleDevices = devices.map(device => {
                descriptions.forEach(desc => {
                    device.IsDefault = true
                    const features = JSON.parse(device.features)
                    const featClass = features.find(feature => {
                        return feature.Id.includes('EC')
                    })
                    
                    if (featureClass.Id === "EC010927") {
                        if (originDevice) {
                            if (originDevice.getManufacturertypedescr().includes('UHKEA')) {
                                if (!device.manufacturerTypeDescr.includes('HKEA-')) {
                                    device = null
                                }
                            } else if (originDevice.getManufacturertypedescr().includes('UHK')) {
                                if (!device.manufacturerTypeDescr.includes('HK-')) {
                                    device = null
                                }
                            } else if (originDevice.getManufacturertypedescr().includes('UTA')) {
                                if (!device.manufacturerTypeDescr.includes('TA-')) {
                                    device = null
                                }
                            } else {
                                if (device.manufacturerTypeDescr.includes('HKEA-')
                                    || device.manufacturerTypeDescr.includes('HK-')
                                    || device.manufacturerTypeDescr.includes('TA-')
                                ) {
                                    device = null
                                }
                            }

                            if (device) {
                                if (originDevice.getManufacturertypedescr().includes('1094')
                                || originDevice.getManufacturertypedescr().includes('1097')
                                ) {
                                    if (!device.manufacturerTypeDescr.includes('1794')) {
                                        device = null
                                    }
                                } else if (originDevice.getManufacturertypedescr().includes('1095')
                                    || originDevice.getManufacturertypedescr().includes('1096')
                                    || originDevice.getManufacturertypedescr().includes('1099')
                                ) {
                                    if (!device.manufacturerTypeDescr.includes('1795')) {
                                        device = null
                                    }
                                }
                            }
                        } else {
                            device = null
                        }
                    }
                    
                    if (device && featClass.Id === desc.feature) {
                        desc.filters.forEach(filter => {
                            featClass.Feature.forEach(feature => {
                                let withoutIcon = true
                                if (filter.Id === "EF003823") {
                                    withoutIcon = false
                                }

                                if (filter.Id === feature.Fname) {
                                    if (filter.Value !== feature.Fvalue) {
                                        device = null
                                    }
                                }
                                
                                if (withoutIcon) {
                                    if (feature.Fname === "EF003823") {
                                        if (feature.Fvalue !== "EV000494"
                                        && feature.Fvalue !== "EV005886") {
                                            device.IsDefault = false
                                        }
                                    }
                                }

                                if (feature.Fname === "EF005040" && feature.Fvalue === "true") {
                                    device.IsDefault = false
                                }
                            })
                        })
                    } else {
                        device = null
                    }
                })
                return device
            }).filter(device => device)

            if (featureClass.Id === "EC000025") {
                const featClass = features.find(feature => {
                    return feature.Id.includes('EC')
                })

                featClass.Feature.forEach(feature => {
                    if (feature.Fname === "EF003911" && feature.Fvalue === "true") {
                        featureClass.Feature.forEach(feature2 => {
                            if (feature2.Fname === "EF003889" && feature2.Fvalue === "false") {
                                if (feature2.Fname === "EF001508" && feature2.Fvalue === "EV000028") {
                                    description = {
                                        feature: 'EC000615',
                                        manufacturerTypeDescr: "-" + articleColor,
                                        filters: [
                                            {Id: "EF000005", Value: "EV006350"},
                                            {Id: "EF000005", Value: "EV006350"}
                                        ],
                                    }
                                    descriptions.push(description)
                                    possibleDevices = descriptions.length > 0
                                        ? calculatePossibleDevice(deviceList, descriptions)
                                        : []
                                }
                            }
                        })
                    }
                })   
            }
        }

        if (featureClass.Id === "EC000615" || featureClass.Id === "EC001588" || featureClass.Id === "EC000025") {
            if (additionalArticles.length > 0) {
                additionalArticles.forEach(el => {
                    if (el) {
                        const additionalDeviceFeatures =  JSON.parse(el.getFeatures())
                        if (additionalDeviceFeatures[0].Id === 'EC000205' || additionalDeviceFeatures[0].Id === 'EC000011') {
                            let countButton = additionalDeviceFeatures[0].Feature.find(el => el.Fname === 'EF003144')
                            
                            if (!countButton) {
                                let ausfuehrung = additionalDeviceFeatures[0].Feature.find(el => el.Fname === 'EF000010')
                                
                                if (ausfuehrung) {
                                    countButton = ausfuehrung.Fvalue === 'EV000317'
                                        ? {Fvalue: "1"}
                                        : ausfuehrung.Fvalue === 'EV007981'
                                            ? {Fvalue: "2"}
                                            : null
                                }
                            }

                            if (countButton) {
                                possibleDevices = possibleDevices.filter(el => {
                                    const additionalDeviceData = additionalData.find(el2 => el2.supplier_pid === el.supplierPid)
                                    
                                    if (!additionalDeviceData) {
                                        return true
                                    }
                                    
                                    if (additionalDeviceData?.feature?.find(el => {
                                        return el.fname === 'excludeFromFlex' && el.fvalue === 'true'
                                    })) {
                                        return false
                                    }
                                    
                                    if (countButton.Fvalue === '1') {
                                        return additionalDeviceData.orientation === 'full'
                                    }

                                    if (countButton.Fvalue === '2') {
                                        return additionalDeviceData.orientation === 'left'
                                            || additionalDeviceData.orientation === 'right'
                                            || additionalDeviceData.orientation === 'both'
                                    }

                                    return true
                                })
                            }
                        }
                    }
                })
            }
        }

        if (noDevice.find(id => id === featureClass.Id)) {
            possibleDevices = possibleDevices.filter(device => {
                const originDevice = deviceList.find(el => el.getSupplierpid() === device.supplierPid)
                if (originDevice) {
                    return originDevice.getDescriptionlong().includes(featureClass.Id)
                }            
                return false
            })
        }
    }
    
    return possibleDevices.filter(device => {
        let additionalCplateData = additionalData.find(el => el.supplier_pid === device.supplierPid)
        if (additionalCplateData) {
            return additionalCplateData.orientation === orientation
                || ((orientation === 'left' || orientation === 'right') && additionalCplateData.orientation === 'both')
        }
        return true
    })

}
